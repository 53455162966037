import React, { useEffect, useState } from 'react';

import './About.css';
function About()

{
    const [showCircle, setShowCircle] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [ishovered, setIsHovered] = useState(null);
    const [canSwitch, setCanSwitch] = useState(true);
    const paragraph = [
    "HTML",
    "CSS",
    "JavaScript",
    "PHP",
    "C#",
    "Java",
    "Python",
    "React JS",
    "Node JS",

    ];
    const tools = ["Git", "Unity", "Npm", "Vs Code", "Visual Studio"]

    useEffect(() => {
        setShowCircle(true);
        setShowInfo(true);
    }, []);

      
   const handleLanguage = () =>{
        setCanSwitch(true);
    }
    const handleOnTools = () =>{
        setCanSwitch(false);
    }

    return(
        <div>
            <div className='infoboxcontainer'>
             <div className="infobox">
             <div className='aboutme'>
                <p>About Me</p>
                <div className='dash'></div>
            </div>
             
                <div className={`circleinfo ${showCircle ? 'show' : ''}`}>
                <img src="mob.jpg" alt="mob"></img>
                </div>
                <p className={`aboutinfo ${showInfo? 'show':''}`}> Hi, I'm <b>Archie Berja.</b> <br /> A passionate Indie Game Developer and an It enthusiast with a wide range of experience that adds depth to my work. Adept at learning and applying new complex technical concepts in a short period of time. Offers well-developed analytical skills and important ability to remain calm in any stressful situation</p>
             </div>
            </div>
            <div>
            <div className='skills'> 
                <p >Skills</p>
                <div className='skilldash'></div>
                
             </div>
             <div className='ToolsBtnContainer'><button className={`ToolsBtn${canSwitch ? ' show' : ''}`} onClick={handleLanguage}>Language</button> <button className={`ToolsBtn${!canSwitch ? ' show' : ''}`} onClick={handleOnTools}>Tools</button> </div>
             <div className='stocks'  > 
             {canSwitch ? paragraph.map((text, index) => (
                 <p   onMouseEnter={() => setIsHovered(index)}
                 onMouseLeave={() =>setIsHovered(null)}  className='techstocks'key={index} >
                    <div className= {`stocksicon ${ ishovered === index && index === 0 ? 'show': ''}`}>
                    {index === 0 ? <img className= {`stocksicon ${ ishovered === index && index === 0 ? 'show': ''}`}src="html.png" alt="mob" /> : null}
                     {index === 1 ? <img className= {`stocksicon ${ ishovered === index && index === 1 ? 'show': ''}`}src="css-3.png" alt="mob" /> : null}
                     {index === 2 ? <img className={`stocksicon ${ ishovered === index && index === 2 ? 'show': ''}`}src="java-script.png" alt="mob" /> : null}
                     {index === 3 ? <img className={`stocksicon ${ ishovered === index && index === 3 ? 'show': ''}`}src="php.png" alt="mob" /> : null}
                     {index === 4 ? <img className= {`stocksicon ${ ishovered === index && index === 4 ? 'show': ''}`}src="c-sharp.png" alt="mob" /> : null}
                     {index === 5 ? <img className= {`stocksicon ${ ishovered === index && index === 5 ? 'show': ''}`}src="java.png" alt="mob" /> : null}
                     {index === 6 ? <img className= {`stocksicon ${ ishovered === index && index === 6 ? 'show': ''}`}src="python.png" alt="mob" /> : null}
                     {index === 7 ? <img className= {`stocksicon ${ ishovered === index && index === 7 ? 'show': ''}`}src="science.png" alt="mob" /> : null}
                     {index === 8 ? <img className= {`stocksicon ${ ishovered === index && index === 8 ? 'show': ''}`}src="node-js.png" alt="mob" /> : null}
                    </div>    
                 {text}
                 {ishovered === index && (index === 0 || index === 1 || index === 4 || index === 7) ? <div className="hover-text">Advance</div> : null}
                 {ishovered === index && (index === 5 || index === 6 || index === 2) ? <div className="hover-text">Intermediate</div> : null}
                 {ishovered === index && (index === 3 || index === 8) ? <div className="hover-text">Beginner</div> : null}
                
                  </p>
                )) : tools.map((text, index) => (
                    <p   onMouseEnter={() => setIsHovered(index)}
                 onMouseLeave={() =>setIsHovered(null)}  className={`techstocks${!canSwitch ? ' show' : ''}`} key={index} >
                    <div className= {`stocksicon ${ ishovered === index && index === 0 ? 'show': ''}`}>
                    {index === 0 ? <img className= {`stocksicon ${ ishovered === index && index === 0 ? 'show': ''}`}src="git.png" alt="mob" /> : null}
                     {index === 1 ? <img className= {`stocksicon ${ ishovered === index && index === 1 ? 'show': ''}`}src="unity.png" alt="mob" /> : null}
                     {index === 2 ? <img className={`stocksicon ${ ishovered === index && index === 2 ? 'show': ''}`}src="npm.png" alt="mob" /> : null}
                     {index === 3 ? <img className={`stocksicon ${ ishovered === index && index === 3 ? 'show': ''}`}src="vscode.png" alt="mob" /> : null}
                     {index === 4 ? <img className= {`stocksicon ${ ishovered === index && index === 4 ? 'show': ''}`}src="vs.png" alt="mob" /> : null}
                    </div>    
                 {text}
                 {ishovered === index && <div className="hover-text">Advance</div>}
                  </p>
                ))}
             
             </div>
           
            </div>
        </div>
    )

}
export default About;