import React from 'react';
import './Project.css';

function Project() {
    const projects = [
        {
          title: 'Personal Website',
          description: 'My personal website. I created this website to display my profile, skills, and projects. As well as my place to try new technology.',
          technologies: 'ReactJs',
          imageUrl: 'mob.jpg',
          altText: 'Personal Website'
        },

        {
          title: 'Shapeshooter',
          description: 'Immerse yourself in an exhilarating arcade experience where you, as the shooter, obliterate geometric shapes whose numerical health values determine their resilience. Shape Shooter offers a captivating journey through a world where precision and strategy reign supreme.',
          technologies: 'Unity',
          imageUrl: 'Shapeshooter.jpg',
          altText: 'Shapeshooter'
        },
        {
            title: 'HeroesArc',
            description: ' Players find themselves thrust into a world teeming with bloodthirsty adversaries and scarce resources. As they navigate the treacherous landscape, they must scavenge for supplies, fortify their defenses, and confront hordes of enemies intent on their demise. With each encounter, players uncover the secrets of their abilities and unravel the mysteries of the world around them.',
            technologies: 'Unity',
            imageUrl: 'HeroesArc.jpg',
            altText: 'HeroesArc'
          },
      
        {
          title: 'Snake3d',
          description: 'transports players into a vibrant and immersive three-dimensional world where they control a serpentine creature for sustenance. In this modern twist on the beloved classic, players must navigate twisting corridors and consume food to grow longer. ',
          technologies: 'Unity',
          imageUrl: 'Snake3d.jpg',
          altText: 'HeroesArc'
        },
        {
            title: 'SuperBrickBreaker',
            description: 'Embark on an exhilarating journey through countless levels of brick-smashing mayhem in this action-packed arcade adventure. Armed with a paddle and ball, players set out to demolish intricate formations of bricks while navigating through a myriad of challenges and power-ups. ',
            technologies: 'Unity',
            imageUrl: 'SuperBrickBreaker.jpg',
            altText: 'SuperBrickBreaker'
          },

          {
            title: 'Limbo',
            description: 'An exciting casino game where players place bets on a randomly generated multiplier. The goal is to predict whether the multiplier will meet or exceed a chosen target, with winnings multiplied accordingly.',
            technologies: 'Unity',
            imageUrl: 'Limbo.jpg',
            altText: 'Limbo'
          }
      ];
    

  return (
    <div>
        <div className='proTitle'>
    <h1>Past Project Experience</h1>
    <p className='proLook'>Take a look at the projects I've done in the past.</p>
    </div>
   
    <div className='proContainer'>
      {projects.map((project, index) => (
        <div key={index} className='projectCard'>
          <img className='projectImg' src={project.imageUrl} alt={project.altText} />
          <div className='projectContent'>
            <h2>{project.title}</h2>
            <p>{project.description}</p>
            <p className='technologies'>{project.technologies}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
  );
};

export default Project;