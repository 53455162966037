import React, { useEffect, useState } from 'react';
import './Home.css';

function Home() {
    const [showCircle, setShowCircle] = useState(false);
    const [showBio, setShowBio] = useState(false);
    const [developerType, setDeveloperType] = useState("Web Developer");

    useEffect(() => {
        setShowCircle(true);
        setShowBio(true);

            const typingInterval = setInterval(() => {
                setDeveloperType(prevType =>
                    prevType === "Game Developer." ? "Web Developer." : "Game Developer."
                );
            }, 4000);
        
            return () => clearInterval(typingInterval);
    }, []);

    return (
        <div>
            <div className={`bio ${showBio ? 'show' : ''}`}>
                <h3>Hello World!</h3>
                <h1>I'm <i>Archie Berja.</i></h1>
                <h3 className="typing">{developerType}</h3>
                <p>Welcome to my personal website.</p>
            </div>
            <div className={`circle ${showCircle ? 'show' : ''}`}>
                <img src="mob.jpg" alt="mob"></img>
            </div>
        </div>
    );
}

export default Home;
