import {  BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from "./NavBar";
import Home from './Home';
import About from './About';
import Project from './Project';
import './App.css';

function App() {
  return (
      <Router>
      <NavBar/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/project' element={<Project/>} />
        </Routes>
      </Router>
  );
}

export default App;