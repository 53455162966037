import { Link, useLocation  } from 'react-router-dom';
import './Navbar.css'
import { useState } from 'react';

function NavBar()
{
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
    return(
        
        <div className='nav'>
               <Link className='Logo' to={'/'}>Logo</Link>
            <nav className={`menu ${isOpen ? 'open' : ''}`}> 
                <Link to={'/'} className={`home${location.pathname === '/' ? ' active' : ''}`}>Home</Link>
                <Link to={'/about'} className={`about${location.pathname === '/about' ? ' active' : ''}`}>About</Link>
                <Link to={'/project'} className={`project${location.pathname === '/project' ? ' active' : ''}`} >Project</Link>
                <Link to={'/'}>Contact</Link>
            </nav>
            <div className="burger-menu">
      <div className={`burger-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      </div>
        </div>
    );
};
export default NavBar;